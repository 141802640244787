import React, { FC, memo, useEffect, useState } from 'react';
import { darken, lighten } from 'color2k';
import * as Slider from '@radix-ui/react-slider';
import styled from '@emotion/styled';
import { Flex } from '$components/layouts';
import { FacetSelectorProps } from './facet-selector-types';
import { Text } from '$components/elements/text';

export const FacetSelectorHelloRetailPrice: FC<FacetSelectorProps> = memo(
    ({ facet, onFacetToggled }: FacetSelectorProps) => {
        const { min = 0, max = 0, currentMin = min || 0, currentMax = max || 0 } = facet;

        const [currentSlideValue, setCurrentSlideValue] = useState<number[]>([currentMin, currentMax]);

        useEffect(() => {
            if (
                ((!facet.currentMin && facet.currentMin !== 0) || !facet.currentMax) &&
                (currentSlideValue[0] !== min || currentSlideValue[1] !== max)
            ) {
                setCurrentSlideValue([min, max]);
            }
            if (
                (facet.currentMin && facet.currentMax && (facet.currentMin < min || facet.currentMax > max)) ||
                (facet.currentMin === min && facet.currentMax === max)
            ) {
                setCurrentSlideValue([min, max]);
            }
        }, [facet]);

        const onFacetChange = (value: number[]) => {
            setCurrentSlideValue(value);
            onFacetToggled?.(facet.key, `${facet.key}:${value[0]},${value[1]}`);
        };

        return (
            <FacetListContainer column alignItems="stretch">
                <SliderRoot
                    min={min}
                    max={max}
                    value={currentSlideValue}
                    onValueChange={setCurrentSlideValue}
                    onValueCommit={onFacetChange}
                >
                    <SliderTrack>
                        <SliderRange />
                    </SliderTrack>
                    <SliderThumb />
                    <SliderThumb />
                </SliderRoot>
                <Text fontWeight="Bold">{`${currentSlideValue[0]} - ${currentSlideValue[1]}`}</Text>
            </FacetListContainer>
        );
    }
);

const FacetListContainer = styled(Flex)({});

const SliderRoot = styled(Slider.Root)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    touchAction: 'none',
    width: '200px',
    height: '20px',
    marginBottom: theme.space[4],
}));

const SliderTrack = styled(Slider.Track)(({ theme }) => ({
    position: 'relative',
    flexGrow: 1,
    backgroundColor: lighten(theme.colors.primary, 0.3),
    borderRadius: '50px',
    height: '3px',
}));

const SliderRange = styled(Slider.Range)(({ theme }) => ({
    position: 'absolute',
    backgroundColor: theme.colors.primary,
    borderRadius: '50px',
    height: '100%',
}));

const SliderThumb = styled(Slider.Thumb)(({ theme }) => ({
    display: 'block',
    width: '20px',
    height: '20px',
    backgroundColor: theme.colors.primary,
    boxShadow: `0 2px 10px ${theme.colors.primary}`,
    borderRadius: '10px',

    '&:hover': {
        backgroundColor: darken(theme.colors.primary, 0.2),
        cursor: 'pointer',
    },
}));
