import React, { FC, memo } from 'react';
import { FILTER_KEY_PRICE, FILTER_KEY_PRICE_HELLO_RETAIL } from '../../helpers';
import { FacetSelectorPrice } from './facet-selector-price';
import { FacetSelectorRegular } from './facet-selector-regular';
import { FacetSelectorProps } from './facet-selector-types';
import { FacetSelectorHelloRetailPrice } from './facet-selector-hello-retail-price';

const SpecialSelectors: { [key: string]: FC<FacetSelectorProps> } = {
    [FILTER_KEY_PRICE]: FacetSelectorPrice,
    [FILTER_KEY_PRICE_HELLO_RETAIL]: FacetSelectorHelloRetailPrice,
};

export const FacetSelector: FC<FacetSelectorProps> = memo((props) => {
    const SpecialSelector = SpecialSelectors[`${props?.facet?.key}`];

    if (SpecialSelector) {
        return <SpecialSelector {...props} />;
    } else {
        return <FacetSelectorRegular {...props} />;
    }
});
