import React, { FC, memo, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { Flex } from '$components/layouts';
import { FacetSelectorProps } from './facet-selector-types';
import { useTranslation } from '$hooks';
import { Checkbox } from '$components/elements/checkbox';
import { InputField } from '$components/elements/input-field';
import { formatString } from '$lib/helpers';

export const FacetSelectorRegular: FC<FacetSelectorProps> = memo(({ facet, onFacetToggled }) => {
    const { translate } = useTranslation();
    const [searchTerm, setSearchTerm] = useState<string>('');

    const searchedFacets = useMemo(
        () =>
            !searchTerm
                ? facet?.facetResults
                : facet?.facetResults?.filter((f) => f.query?.name?.toLowerCase().includes(searchTerm.toLowerCase())),
        [searchTerm, facet]
    );

    if (!facet?.facetResults) {
        return <></>;
    }

    const removeTrailingDollar = (str?: string) => {
        if (!str) {
            return str;
        }
        return str.endsWith('$') ? str.slice(0, -1) : str;
    };

    return (
        <FacetListContainer column alignItems="stretch">
            <FacetSearchWrapper>
                <InputField
                    value={searchTerm}
                    onChange={({ target: { value } }) => setSearchTerm(value)}
                    label={`${formatString(
                        translate('search.filterAndFacets.facetSearch'),
                        (facet.name || '').toLowerCase()
                    )} `}
                />
            </FacetSearchWrapper>
            {searchedFacets
                ?.filter((fr) => !!fr.count && !!fr.query?.name && fr.query?.value)
                ?.slice(0, 10)
                .map((fr) => (
                    <Facet key={fr.query?.value}>
                        <Checkbox
                            label={
                                <FacetLabel>
                                    {removeTrailingDollar(fr.query?.name)} <span>{`(${fr.count})`}</span>
                                </FacetLabel>
                            }
                            onChange={() => onFacetToggled(facet.key, fr.query?.value)}
                            checked={fr.isSelected}
                        />
                    </Facet>
                ))}
        </FacetListContainer>
    );
});

const FacetListContainer = styled(Flex)({});

const FacetSearchWrapper = styled.div(({ theme }) => ({
    marginBottom: theme.space[6],
}));

const Facet = styled.div(({ theme }) => ({
    '&:not(:last-of-type)': {
        marginBottom: theme.space[4],
    },
}));

const FacetLabel = styled.span(({ theme }) => ({
    ...theme.mixins.useTextStyle('body'),

    span: {
        color: theme.colors.grey40,
    },
}));
