import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Flex } from '$components/layouts';
import { FacetSelectorProps } from './facet-selector-types';
import { useTranslation } from '$hooks';
import { Checkbox } from '$components/elements/checkbox';

type PriceFacet = {
    min: number;
    max: number;
    toggled: boolean;
};

const PRICE_FACETS: PriceFacet[] = [
    { min: 0, max: 100, toggled: false },
    { min: 101, max: 200, toggled: false },
    { min: 201, max: 300, toggled: false },
    { min: 301, max: 400, toggled: false },
    { min: 401, max: -1, toggled: false },
];

const isToggled = (priceFacet: PriceFacet, currentMin: number, currentMax: number) => {
    return (
        (priceFacet.min >= currentMin && priceFacet.max <= currentMax && priceFacet.max > 0) ||
        (priceFacet.max < 0 && currentMax > priceFacet.min)
    );
};

export const FacetSelectorPrice: FC<FacetSelectorProps> = memo(({ facet, onFacetToggled }: FacetSelectorProps) => {
    const { min = 0, max = 0, currentMin = 0, currentMax = 0 } = facet;
    const [pristine, setPristine] = useState(currentMax === max && currentMin === min);
    const [priceFacets, setPriceFacets] = useState<PriceFacet[]>(
        [...PRICE_FACETS]
            .filter((pf) => pf.min >= min || (pf.max > 0 ? pf.min <= max : pf.max <= max))
            .map((pf) => ({
                ...pf,
                toggled:
                    isToggled(pf, currentMin, currentMax) &&
                    currentMax !== max &&
                    currentMin !== min &&
                    (facet?.isSelected || false),
            }))
    );
    const { translate } = useTranslation();
    const [valuesToSearch, setValuesToSearch] = useState([currentMin, currentMax]);

    useEffect(() => {
        if (valuesToSearch[0] === min && valuesToSearch[1] === max) {
            onFacetToggled(facet.key, undefined);
        } else {
            onFacetToggled(facet.key, `${valuesToSearch[0]}|${valuesToSearch[1] < 0 ? max : valuesToSearch[1]}`);
        }
    }, [valuesToSearch]);

    useEffect(() => {
        const toggledFacets = priceFacets.filter((pf) => pf.toggled);
        if (toggledFacets.length) {
            setPristine(false);
            setValuesToSearch([toggledFacets[0].min, toggledFacets[toggledFacets.length - 1].max]);
        } else {
            setPristine(true);
            setValuesToSearch([min, max]);
        }
    }, [priceFacets]);

    const isInbetween = useCallback(
        (priceFacet: PriceFacet) => {
            return (
                priceFacet.min > currentMin &&
                priceFacet.max < currentMax &&
                priceFacet.max > -1 &&
                !pristine &&
                !(currentMax === max && currentMin === min)
            );
        },
        [currentMin, currentMax, pristine]
    );

    const handleToggle = (idx: number) => {
        setPriceFacets((prev) => {
            const updatedFacets = [...prev];
            updatedFacets[idx].toggled = !updatedFacets[idx].toggled;

            const toggledFacets = priceFacets.filter((pf) => pf.toggled);

            if (toggledFacets.length) {
                const newMin = toggledFacets[0].min;
                const newMax =
                    toggledFacets[toggledFacets.length - 1].max < 0 ? max : toggledFacets[toggledFacets.length - 1].max;
                return prev.map((pf) => ({
                    ...pf,
                    toggled: isToggled(pf, newMin, newMax),
                }));
            } else {
                return updatedFacets;
            }
        });
    };

    return (
        <FacetListContainer column alignItems="stretch">
            {priceFacets.map((priceFacet, idx) => {
                const disabled = isInbetween(priceFacet);
                return (
                    <Facet key={priceFacet.min}>
                        <Checkbox
                            label={
                                <FacetLabel>
                                    {priceFacet.max > 0
                                        ? `${priceFacet.min} - ${priceFacet.max} ${translate('general.currencyLabel')}`
                                        : `${priceFacet.min}+ ${translate('general.currencyLabel')}`}
                                </FacetLabel>
                            }
                            disabled={disabled}
                            onChange={() => handleToggle(idx)}
                            checked={priceFacet.toggled}
                        />
                    </Facet>
                );
            })}
        </FacetListContainer>
    );
});

const FacetListContainer = styled(Flex)({});

const Facet = styled.div(({ theme }) => ({
    '&:not(:last-of-type)': {
        marginBottom: theme.space[4],
    },
}));

const FacetLabel = styled.span(({ theme }) => ({
    ...theme.mixins.useTextStyle('body'),

    span: {
        color: theme.colors.grey40,
    },
}));
